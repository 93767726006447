/*-- Space-Class --*/

[class|=space] {
    display: block;
    width: 100%;
    overflow: hidden
}

.space-10 {
    height: 10px;
}

.space-20 {
    height: 20px;
}

.space-30 {
    height: 30px;
}

.space-40 {
    height: 40px;
}

.space-50 {
    height: 50px;
}

.space-60 {
    height: 60px;
}

.space-70 {
    height: 70px;
}

.space-80 {
    height: 80px;
}

.space-90 {
    height: 90px;
}

.space-100 {
    height: 100px;
}

/* Medium Layout: 1280px. */

@media only screen and (min-width: 992px) and (max-width: 1280px) {
    .mainmenu-area ul.nav.navbar-nav li a {
        margin: 0 10px;
    }
    .feature-area,
    .testimonial-area,
    .video-area,
    .progress-area {
        background-image: none;
        margin-bottom: 0;
    }
    .testimonial-area {
        padding: 100px 0;
    }
    .team-slide .owl-controls .owl-nav {
        text-align: center;
    }
    .team-slide .owl-controls .owl-nav > div {
        position: static;
        display: inline-block;
        margin: 30px 15px 15px 15px;
    }
    .footer-bottom .footer-menu ul,
    .footer-area {
        text-align: center;
    }
    .angle:after {
        height: 80px;
    }
}

/* Tablet Layout: 768px. */

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .home-area {
        padding: 100px 0;
        margin-bottom: 0;
    }
    .mainmenu-area ul.nav.navbar-nav li a {
        margin: 0 10px;
    }
    .video-area,
    .progress-area {
        background-image: none;
    }
    .mobile-image {
        /* display: none !important; */
        width: 200px;
    }
    .feature-area,
    .video-area,
    .progress-area {
        background-image: none;
        margin-bottom: 0;
        margin-top: 0;
    }
    .testimonial-area {
        padding: 60px 0;
    }

    .video-photo {
        margin-bottom: 60px;
    }
    .team-slide .owl-controls .owl-nav {
        text-align: center;
    }
    .team-slide .owl-controls .owl-nav > div {
        position: static;
        display: inline-block;
        margin: 30px 15px 15px 15px;
    }
    .footer-bottom .footer-menu ul,
    .footer-area {
        text-align: center;
    }
    .angle:after {
        height: 80px;
    }
}

/* Mobile Layout: 320px. */

@media only screen and (max-width: 767px) {
    body {
        font-size: 14px;
    }
    h1 {
        font-size: 40px;
    }
    h3 {
        font-size: 22px;
    }
    .bttn-white {
        padding: 8px 30px;
    }
    .section-padding {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .angle:after {
        height: 60px;
    }
    /*-- Main-Menu-Style --*/
    .mainmenu-area {
        padding: 15px 0;
    }
    .mainmenu-area:before {
        opacity: 0.9;
    }
    .mainmenu-area .navbar-toggle {
        border: 1px solid #ffffff;
        border-radius: 1px;
    }
    .mainmenu-area .navbar-toggle .icon-bar {
        background-color: #ffffff;
        height: 2px;
        border-radius: 0;
    }
    .mainmenu-area #primary_menu {
        overflow-y: auto;
        max-height: 80vh;
    }
    .mainmenu-area #primary_menu ul.nav.navbar-nav li {
        display: block;
    }
    .mainmenu-area #primary_menu ul.nav.navbar-nav li a {
        padding: 15px;
        border: none;
    }
    .mainmenu-area #primary_menu ul.nav.navbar-nav li.active a,
    .mainmenu-area #primary_menu ul.nav.navbar-nav li a:hover {
        background-color: #ffffff;
        color: #5e88fc;
    }
    /*-- Home-Area --*/
    .home-area {
        padding-top: 50px;
        padding-bottom: 100px;
        margin-bottom: 0;
    }
    .mobile-image {
        /* display: none !important; */
        width: 200px !important;
    }
    .feature-area,
    .video-area,
    .progress-area {
        background-image: none;
        margin-bottom: 0;
        margin-top: 0;
    }
    .testimonial-area {
        padding: 60px 0;
    }
    .team-slide .owl-controls .owl-nav {
        text-align: center;
    }
    .team-slide .owl-controls .owl-nav > div {
        position: static;
        display: inline-block;
        margin: 30px 15px 15px 15px;
    }
    .gallery-slide {
        margin-bottom: 60px;
    }
    .gallery-slide .item {
        margin: 85px 25px 90px 30px;
    }
    .gallery-slide .owl-controls {
        position: static;
        margin-top: 30px;
        width: 100%;
        text-align: center;
    }
    .footer-bottom .footer-menu ul,
    .footer-area {
        text-align: center;
    }
    .subscribe-form {
        padding: 20px;
    }
    .subscribe-form form .bttn-white {
        position: static;
        margin-top: 20px;
    }
    .subscribe-form form .control {
        padding-right: 15px;
    }

    .post-single.sticky .post-media {
        margin-right: 0;
        float: none;
        width: 100%;
    }

    .form-double .box {
        width: 100%;
        float: none;
    }
    .post-single {
        padding: 20px;
    }
    .post-single h2 {
        font-size: 26px;
    }
    .post-single blockquote {
        padding: 10px;
        font-size: 14px;
    }
    .comments-list .comment {
        padding-left: 0;
    }
    .comments-list .comment .comment-pic {
        position: static;
        margin-bottom: 30px;
    }
}

/* Wide Mobile Layout: 480px. */

@media only screen and (min-width: 480px) and (max-width: 767px) {}